<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <v-col sm="6" md="3" lg="2" xl="2">
          <FormButton text="Novo gateway" type="primario" full @click="onNew" />
        </v-col>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        sortBy="name"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:provider_name="{ col }">
          <img :src="renderLogo(col)" style="max-width:70px" />
        </template>
        <template v-slot:endpoint_transacional="{ row }">
          {{ renderAmbiente(row) }}
        </template>
        <template v-slot:antifraude_ativar="{ row, col }">
          <div v-if="col">
            <p style="margin:0;">Antifraude: ativado</p>
            <p style="margin:0;">
              Confiança mínima: {{ row.antifraude_confianca_transacao }} pontos
            </p>
          </div>
          <div v-else>
            <p style="margin:0;">Antifraude: desativado</p>
          </div>
        </template>
        <template v-slot:suporta="{ col }">
          <div v-if="Array.isArray(col)">
            <span v-if="col.includes('CREDITO')" class="credito">Crédito</span>
            <span v-if="col.includes('BOLETO')" class="boleto">Boleto</span>
            <span v-if="col.includes('DEBITO')" class="debito">Débito</span>
            <span v-if="col.includes('PIX')" class="pix">PIX</span>
          </div>
          <div v-else></div>
        </template>
        <template v-slot:idgateway="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { mapGetters } from 'vuex'
import { GATEWAY_REMOVE, GATEWAY_CONFIG } from '@/store/actions/gateway'
import * as _ from 'lodash'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'GatewayTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Tipo',
        align: 'left',
        value: 'provider_name'
      },
      { text: 'Nome', value: 'name' },
      {
        text: 'Ambiente',
        value: 'endpoint_transacional'
      },
      {
        text: 'Antifraude',
        value: 'antifraude_ativar'
      },
      {
        text: 'Suporte',
        value: 'suporta'
      },
      {
        text: 'Opções',
        value: 'idgateway',
        clickable: false
      }
    ],
    providerImage: {
      CIELO: '/images/cielo_logo.jpg',
      REDECARD: '/images/redecard_logo.png',
      MUNDIPAGG: '/images/mundipagg.svg',
      PAGHIPER: '/images/paghiper.png',
      MERCADOPAGO: '/images/mercadopago_logo.png'
    },
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getGatewaysOpcoes',
      'moduloFornecedorAtivo'
    ]),
    endpoint() {
      return `/api/v4/gateways/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {
    this.$store.dispatch(GATEWAY_CONFIG)
  },
  methods: {
    onClose() {
      this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`gateway/${item.idgateway}`)
    },
    onNew() {
      this.$router.push('gateway/novo')
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onDelete(item) {
      this.$swal
        .fire({
          title: `Remover gateway de pagamento? `,
          text: 'Essa ação é irreversível',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) this.delete(item)
          else return
        })
    },
    delete(item) {
      this.$store.dispatch(GATEWAY_REMOVE, item.idgateway).then(() => {
        this.$vueOnToast.pop('success', 'Gateway removido com sucesso')
        this.$refs.datatable.reload()
      })
    },
    renderAmbiente(gateway) {
      const label = {
        PRODUCAO: 'Produção',
        SANDBOX: 'Homologação',
        HOMOLOGACAO: 'Homologação'
      }
      switch (gateway.provider_name.toUpperCase()) {
        case 'CIELO': {
          return gateway.endpoint_transacional.includes('sandbox')
            ? 'Homologação'
            : 'Produção'
        }
        case 'REDECARD': {
          return gateway.endpoint_transacional.includes('desenvolvedores')
            ? 'Homologação'
            : 'Produção'
        }
        default:
          return label[gateway.ambiente] || 'Produção'
      }
    },
    renderLogo(provicder) {
      const gateway = this.getGatewaysOpcoes.find(item => item.id === provicder)
      return _.get(gateway, 'logo', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.credito,
.boleto,
.debito,
.pix {
  padding: 5px;
  margin: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
}
.credito {
  background-color: var(--secondary-color);
}
.boleto {
  background-color: var(--primary-color);
}
.debito {
  background-color: var(--primary-2-color);
}
.pix {
  background-color: #3db4aa;
}
</style>
